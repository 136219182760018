<template>
  <div class="seleData">
    <AmountInput v-bind="$attrs" v-on="$listeners" clearable @blur="blur" />
    <base-select
      style="width: 50%; margin-left: 10%"
      :disabled='$attrs.disabled'
      v-model="validTimeUnit"
      :options="options"
      :selectedField="['dictId', 'dictName']"
    />
  </div>
</template>
<script>
import { getDictLists } from '@/filters/fromDict'
import BaseSelect from '@/components/common/base-select/base-select.vue'
import AmountInput from '@/components/input/amount-input/amount-input.vue'
export default {
  components: { AmountInput, BaseSelect },
  props: {},
  data () {
    return {

    }
  },
  // 计算属性 类似于data概念
  computed: {
    options () {
      return this.getDict()
    },
    validTimeUnit: {
      get () {
        return this.$attrs.validTimeUnit
      },
      set (val) {
        this.$emit('update', val)
      }
    }
  },
  methods: {
    blur () {
      this.$emit('blurInput', '')
    },
    getDict () {
      if (this.$attrs.dictType === 'MM') {
        console.log(getDictLists('DATE_UNIT'))
        if (getDictLists('DATE_UNIT')) {
          const dictLists = getDictLists('DATE_UNIT')
          const arr = []
          dictLists.forEach((item) => {
            if (item.dictId === 'MONTH') {
              arr.push(item)
            }
          })
          return arr
        }
      } else {
        return getDictLists('DATE_UNIT')
      }
    }
  }
}
</script>
<style lang='scss' scoped>
.seleData{
  display: flex;
  justify-content: flex-start;
}
/deep/ .el-select--small{
  margin-left: 0 !important;
}
</style>
