<template>
  <!-- 质押信息模块 -->
  <div class="pledgeInfo">
    <!-- <title-center name="质押信息"></title-center> -->
    <div class="pledgor" v-for="(item ,index) in pledgorList" :key="index">
      <div class="pledgor_top">
        <div>
          <div class="item"> 出质人名称：{{item.name}}</div>
          <div class="item">统一社会信用代码：{{item.creditCode}}</div>
        </div>
        <div class="addbtn" v-if="!isDetail">
          <div class="addbutton" @click="addPledgorData(item)">
            <i class="el-icon-plus"></i>
          </div>
        </div>
      </div>
      <!-- 表格数据选择输入数据 -->
      <base-table
        :columns="pledgorForm"
        :tableAttrs="{
          data: item.pledgorData,
          stripe: true,
        }"
        :key="index"
        :isCaculateHeight="false"
        :showPage="false"
      >
        <template slot="index" slot-scope="scope">{{
          scope.$index + 1
        }}</template>
        <template slot="finContractNameHeader">
          <font style="color:#EE1C1C;">*</font>合同名称
        </template>
        <template slot="finContractNamesHeader">
          <font style="color:#EE1C1C;">*</font>实际合同名称
        </template>
        <template slot="pledgeValueHeader">
          <font style="color:#EE1C1C;">*</font>质押价值(元)
        </template>
        <template slot="relLoanAmountHeader">
          <font style="color:#EE1C1C;">*</font>对应融资额(元)
        </template>
        <template slot="pledgeRateHeader">
        <font style="color: #ee1c1c">*</font>质押率(%)
      </template>
        <template slot="finContractName" slot-scope="scope">
          <base-select
            v-model="scope.row.finContractId"
            filterable
            @change="
              finContractChange(scope.row, scope.row.finContractId,item.contractData,item.pledgorData,scope.$index)
            "
            :options="item.contractData"
            :selectedField="['keyId', 'contractName']"
            :disabled="isDetail"
          ></base-select>
        </template>
        <template slot="finContractNames" slot-scope="scope">
          <el-input
            v-model="scope.row.finContractName"
            placeholder="请输入"
            :disabled="isDetail"
          ></el-input>
        </template>
        <template slot="projectDebtor" slot-scope="scope">
          <el-input
            v-model="scope.row.projectDebtor"
            placeholder="请输入"
            :disabled="isDetail"
          ></el-input>
        </template>
        <template slot="finContractCode" slot-scope="scope">
          <el-input
            v-model="scope.row.finContractCode"
            placeholder="请输入"
            :disabled="true"
          ></el-input>
        </template>
        <template slot="finContractAmount" slot-scope="scope">
          <amount-input
            v-model="scope.row.finContractAmount"
            placeholder="请输入"
             @input="finContractAmountInput(scope.row.finContractAmount,scope.$index,index)"
            type='1000Y'
            :disabled="isDetail"
          ></amount-input>
        </template>
        <template slot="pledgeValue" slot-scope="scope">
          <amount-input
            v-model="scope.row.pledgeValue"
            placeholder="请输入"
            type='1000Y'
            :disabled="isDetail"
            @change="pledgeValueChange(scope.row.pledgeValue,scope.$index,index)"
          ></amount-input>
        </template>
        <template slot="relLoanAmount" slot-scope="scope">
          <amount-input
            v-model="scope.row.relLoanAmount"
            placeholder="请输入"
            :disabled="isDetail"
            type='1000Y'
             @input="changeRelLoanAmount(scope.row.relLoanAmount, scope.$index,index)"
          ></amount-input>
        </template>
        <template slot="pledgeRate" slot-scope="scope">
        <amount-input
          v-model="scope.row.pledgeRate"
          placeholder="请输入"
          :disabled="true"
         type='Y'
        ></amount-input>
      </template>
        <template slot="action" slot-scope="scope" v-if="!isDetail">
          <IconButton
            content="删除"
            @click="removePreLoanDate(item, scope.$index)"
            icon="iconfont iconshanchu1"
          ></IconButton>
        </template>
      </base-table>
    </div>
  </div>
</template>
<script>
import BaseTable from '@/components/common/table/base-table/base-table.vue'
// import titleCenter from '../../components/title-center.vue'
import IconButton from '@/components/common/button/icon-button/icon-button.vue'
import { pledgorForm } from '../utils/config'
import BaseSelect from '@/components/common/base-select/new-base-select.vue'
import AmountInput from '@/components/input/amount-input/amount-input.vue'
export default {
  components: { BaseTable, IconButton, BaseSelect, AmountInput },
  name: 'pledgeInfo',
  props: {
    formData: Object,
    pledgorList: Array,
    contractInfoData: Array, // 补充合同数据
    isDetail: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {

    }
  },
  watch: {
    // pledgorList: {
    //   handler (val) {
    //     if (val) {
    //       this.change()
    //     }
    //   },
    //   deep: true
    // }
  },
  computed: {
    pledgorForm () {
      return pledgorForm(this)
    }
  },
  mounted () {

  },
  methods: {
    finContractAmountInput () {

    },
    // 对应融资额修改
    changeRelLoanAmount (value, index, bigIndex) {
      if (value && this.pledgorList[bigIndex].pledgorData[index].pledgeValue) {
        this.$set(
          this.pledgorList[bigIndex].pledgorData[index],
          'pledgeRate',
          (value / this.pledgorList[bigIndex].pledgorData[index].pledgeValue * 100).toFixed(2)
        )
      }
      if (!value) {
        this.$set(
          this.pledgorList[bigIndex].pledgorData[index],
          'pledgeRate',
          ''
        )
      }
    },
    // 质押价值修改
    pledgeValueChange (value, index, bigIndex) {
      if (value === '0' || !value) {
        this.warning('请输入质押价值且质押价值必须大于0')
        return false
      }
      if (value && this.pledgorList[bigIndex].pledgorData[index].relLoanAmount) {
        this.$set(
          this.pledgorList[bigIndex].pledgorData[index],
          'pledgeRate',
          (this.pledgorList[bigIndex].pledgorData[index].relLoanAmount / value * 100).toFixed(2)
        )
      }
    },
    inputpledgeValue (data) {

    },
    // 选择合同企业
    finContractChange (data, item, list, dataList, index) {
      let status = true
      if (this.contractInfoData) {
        const isSelectCode = this.contractInfoData.some(item => item.finContractId === data.finContractId)
        if (isSelectCode) {
          this.$set(data, 'finContractId', '')
          return this.warning('合同不能相同，请重新选择')
        }
      }
      dataList.forEach((ele, indexs) => {
        if (ele.keyIds === item && index !== indexs) {
          status = false
        }
      })
      if (!status) {
        this.$set(data, 'finContractId', '')
        return this.warning('合同不能相同，请重新选择')
      }

      list.forEach((ele) => {
        if (ele.keyId === item) {
          data.projectDebtor = ele.xmgsName
          data.finContractCode = ele.contractCode
          data.finContractAmount = ele.contractAmount
          this.$set(data, 'finContractName', ele.contractName)
          data.contractName = ele.contractName
          data.pledgeValue = ele.pledgeValue
          data.keyIds = ele.keyId // 用户补充合同查询过滤
        }
      })
      if (!item) {
        data.projectDebtor = ''
        data.finContractCode = ''
        this.$set(data, 'finContractName', '')
        data.finContractAmount = ''
        data.contractName = ''
        data.pledgeValue = ''
        data.relLoanAmount = ''
        data.keyIds = ''
      }
      this.$emit('clearRepaymentPlan', '')
    },
    // 新增质押信息
    addPledgorData (item) {
      console.log(this.formData, '请先选择融资模式')
      if (!this.formData.financeType) {
        return this.warning('请先选择融资模式')
      }
      const data = {
        finContractName: '',
        projectDebtor: '',
        finContractId: '',
        finContractCode: '',
        finContractAmount: '',
        pledgeValue: '',
        relLoanAmount: '',
        gysId: item.gysId
      }
      item.pledgorData.push(data)
      this.$emit('clearRepaymentPlan', '')
    },
    // 删除质押信息
    removePreLoanDate (item, index) {
      item.pledgorData.splice(index, 1)
      this.$emit('clearRepaymentPlan', '')
    }
  }
}
</script>
<style lang="scss" src='../index.scss' scoped></style>
